import { default as indexVN93EJK4S8Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue?macro=true";
import { default as indexjHf5dFRMYpMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue?macro=true";
import { default as indexuetSkcjwsDMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue?macro=true";
import { default as indexBw1sJhUvdlMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue?macro=true";
import { default as indexGUuGYTNlmwMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue?macro=true";
import { default as indexl0LDXTAPxuMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue?macro=true";
import { default as indexMYRynjM7PSMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue?macro=true";
import { default as indexOUyDKEkoBlMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue?macro=true";
import { default as indexpqmq1V3BRkMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue?macro=true";
import { default as indexU0NMFENY61Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue?macro=true";
import { default as indexIUsxSfuaJNMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue?macro=true";
import { default as indexTl6NvlQBi5Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue?macro=true";
import { default as indexX8agzTrVDVMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue?macro=true";
import { default as indexhruc4HfwSsMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue?macro=true";
import { default as indexR8OCn2Nh0cMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue?macro=true";
import { default as indexxtNbeGi5dzMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue?macro=true";
import { default as indexfl3eBFeG9PMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue?macro=true";
import { default as index8X54hlZ5W2Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue?macro=true";
import { default as indexNMh63dzoW2Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue?macro=true";
import { default as indexeLYbSHhVxXMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue?macro=true";
import { default as indexYJI8Xx14K8Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue?macro=true";
import { default as indexXW1vsuWL46Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue?macro=true";
import { default as indexds0VpMgC2kMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue?macro=true";
import { default as indexGyuUiDf2fgMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue?macro=true";
import { default as indexYa6fRoWh84Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue?macro=true";
import { default as indexxnV5MLSQcOMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue?macro=true";
import { default as indexpKGfELpGvTMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue?macro=true";
import { default as indexqRFmcmK0FaMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue?macro=true";
import { default as index7vCFUXXTDQMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue?macro=true";
import { default as indexIxaNJTz7CPMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue?macro=true";
import { default as indexxFqNkPV5JcMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue?macro=true";
import { default as index98pDDn51IGMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue?macro=true";
import { default as _91_46_46_46all_93AF9oPoK06oMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue?macro=true";
import { default as indexZD9Y4F7DMJMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue?macro=true";
import { default as indexnjaRq1UEOhMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue?macro=true";
import { default as indexH3yeQ0mj9wMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue?macro=true";
import { default as _91_46_46_46all_93lfvzZJVF2AMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue?macro=true";
import { default as indexgg73T1N1MHMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue?macro=true";
import { default as indexrCKlJClxxIMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue?macro=true";
import { default as _91_46_46_46all_93IlrBS9zz52Meta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue?macro=true";
import { default as indexnmYsqt9NqiMeta } from "/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___de",
    path: "/de/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___en",
    path: "/en/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___nl",
    path: "/nl/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___fr",
    path: "/fr/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___it",
    path: "/it/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___es",
    path: "/es/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___bg",
    path: "/bg/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___bs",
    path: "/bs/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___cs",
    path: "/cs/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___da",
    path: "/da/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___el",
    path: "/el/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___et",
    path: "/et/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___fi",
    path: "/fi/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___hr",
    path: "/hr/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___hu",
    path: "/hu/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___lt",
    path: "/lt/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___lv",
    path: "/lv/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___no",
    path: "/no/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___pl",
    path: "/pl/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___pt",
    path: "/pt/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___ro",
    path: "/ro/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___ru",
    path: "/ru/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___sk",
    path: "/sk/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___sl",
    path: "/sl/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___sr",
    path: "/sr/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___sv",
    path: "/sv/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-mail___tr",
    path: "/tr/account/change-mail",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-mail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___de",
    path: "/de/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___en",
    path: "/en/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___nl",
    path: "/nl/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___fr",
    path: "/fr/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___it",
    path: "/it/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___es",
    path: "/es/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___bg",
    path: "/bg/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___bs",
    path: "/bs/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___cs",
    path: "/cs/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___da",
    path: "/da/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___el",
    path: "/el/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___et",
    path: "/et/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___fi",
    path: "/fi/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___hr",
    path: "/hr/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___hu",
    path: "/hu/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___lt",
    path: "/lt/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___lv",
    path: "/lv/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___no",
    path: "/no/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___pl",
    path: "/pl/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___pt",
    path: "/pt/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___ro",
    path: "/ro/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___ru",
    path: "/ru/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___sk",
    path: "/sk/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___sl",
    path: "/sl/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___sr",
    path: "/sr/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___sv",
    path: "/sv/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-change-password___tr",
    path: "/tr/account/change-password",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___de",
    path: "/de/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___en",
    path: "/en/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___nl",
    path: "/nl/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___fr",
    path: "/fr/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___it",
    path: "/it/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___es",
    path: "/es/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___bg",
    path: "/bg/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___bs",
    path: "/bs/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___cs",
    path: "/cs/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___da",
    path: "/da/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___el",
    path: "/el/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___et",
    path: "/et/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___fi",
    path: "/fi/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___hr",
    path: "/hr/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___hu",
    path: "/hu/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___lt",
    path: "/lt/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___lv",
    path: "/lv/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___no",
    path: "/no/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___pl",
    path: "/pl/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___pt",
    path: "/pt/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___ro",
    path: "/ro/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___ru",
    path: "/ru/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___sk",
    path: "/sk/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___sl",
    path: "/sl/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___sr",
    path: "/sr/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___sv",
    path: "/sv/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-email-change___tr",
    path: "/tr/account/confirm-email-change",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-email-change/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___de",
    path: "/de/account/neues-passwort",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___en",
    path: "/en/account/new-password",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___nl",
    path: "/nl/account/bevestiging-wachtwoord",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___fr",
    path: "/fr/account/confirmer-mot-de-passe",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___it",
    path: "/it/account/conferma-password",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___es",
    path: "/es/account/nueva-contrase%C3%B1a",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___bg",
    path: "/bg/account/%D0%BF%D0%BE%D1%82%D0%B2%D1%8A%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D0%B0",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___bs",
    path: "/bs/account/potvrdite-lozinku",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___cs",
    path: "/cs/account/potvrzeni-hesla",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___da",
    path: "/da/account/bekr%C3%A6ft-adgangskode",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___el",
    path: "/el/account/%CE%B5%CF%80%CE%B9%CE%B2%CE%B5%CE%B2%CE%B1%CE%B9%CF%89%CF%83%CE%B7-%CE%BA%CF%89%CE%B4%CE%B9%CE%BA%CE%BF%CF%85-%CF%80%CF%81%CE%BF%CF%83%CE%B2%CE%B1%CF%83%CE%B7%CF%82",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___et",
    path: "/et/account/kinnita-salasona",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___fi",
    path: "/fi/account/vahvista-salasana",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___hr",
    path: "/hr/account/potvrdi-lozinku",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___hu",
    path: "/hu/account/jelszo-megerosites",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___lt",
    path: "/lt/account/patvirtinti-slaptazodi",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___lv",
    path: "/lv/account/apstiprini-paroli",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___no",
    path: "/no/account/bekreft-passord",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___pl",
    path: "/pl/account/potwierdz-haslo",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___pt",
    path: "/pt/account/confirme-a-senha",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___ro",
    path: "/ro/account/confirmare-parola",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___ru",
    path: "/ru/account/%D0%BF%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B4%D0%B8%D1%82%D0%B5-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D1%8C",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___sk",
    path: "/sk/account/potvrdit-heslo",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___sl",
    path: "/sl/account/potrdite-geslo",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___sr",
    path: "/sr/account/potvrda-lozinke",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___sv",
    path: "/sv/account/bekrafta-losenord",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirm-password___tr",
    path: "/tr/account/sifreyi-onayla",
    meta: indexGUuGYTNlmwMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirm-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___de",
    path: "/de/account/email-bestaetigen",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___en",
    path: "/en/account/confirm-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___nl",
    path: "/nl/account/bevestiging-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___fr",
    path: "/fr/account/confirmer-e-mail",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___it",
    path: "/it/account/conferma-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___es",
    path: "/es/account/confirmar-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___bg",
    path: "/bg/account/%D0%BF%D0%BE%D1%82%D0%B2%D1%8A%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B8%D0%BC%D0%B5%D0%B9%D0%BB",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___bs",
    path: "/bs/account/potvrdite-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___cs",
    path: "/cs/account/potvrzeni-e-mailu",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___da",
    path: "/da/account/bekr%C3%A6ft-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___el",
    path: "/el/account/%CE%B5%CF%80%CE%B9%CE%B2%CE%B5%CE%B2%CE%B1%CE%B9%CF%89%CF%83%CE%B7-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___et",
    path: "/et/account/kinnita-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___fi",
    path: "/fi/account/vahvista-sahkoposti",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___hr",
    path: "/hr/account/potvrdi-e-adresu",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___hu",
    path: "/hu/account/email-megerosites",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___lt",
    path: "/lt/account/patvirtinti-el-pasto-adresa",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___lv",
    path: "/lv/account/apstiprini-e-pastu",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___no",
    path: "/no/account/bekreft-e-postadresse",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___pl",
    path: "/pl/account/potwierdz-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___pt",
    path: "/pt/account/confirmar-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___ro",
    path: "/ro/account/confirmare-e-mail",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___ru",
    path: "/ru/account/%D0%BF%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B4%D0%B8%D1%82%D0%B5-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D1%8B%D0%B8-%D0%B0%D0%B4%D1%80%D0%B5%D1%81",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___sk",
    path: "/sk/account/potvrdit-e-mail%20",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___sl",
    path: "/sl/account/potrdite-email",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___sr",
    path: "/sr/account/imejl-potvrda",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___sv",
    path: "/sv/account/bakrafta-e-postadressen",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-confirmEmail___tr",
    path: "/tr/account/e-postayi-onayla",
    meta: indexl0LDXTAPxuMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/confirmEmail/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___de",
    path: "/de/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___en",
    path: "/en/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___nl",
    path: "/nl/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___fr",
    path: "/fr/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___it",
    path: "/it/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___es",
    path: "/es/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___bg",
    path: "/bg/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___bs",
    path: "/bs/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___cs",
    path: "/cs/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___da",
    path: "/da/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___el",
    path: "/el/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___et",
    path: "/et/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___fi",
    path: "/fi/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___hr",
    path: "/hr/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___hu",
    path: "/hu/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___lt",
    path: "/lt/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___lv",
    path: "/lv/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___no",
    path: "/no/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___pl",
    path: "/pl/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___pt",
    path: "/pt/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___ro",
    path: "/ro/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___ru",
    path: "/ru/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___sk",
    path: "/sk/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___sl",
    path: "/sl/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___sr",
    path: "/sr/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___sv",
    path: "/sv/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account-favorites___tr",
    path: "/tr/account/favorites",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/de/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___nl",
    path: "/nl/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fr",
    path: "/fr/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___it",
    path: "/it/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___bg",
    path: "/bg/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___bs",
    path: "/bs/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___cs",
    path: "/cs/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___da",
    path: "/da/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___el",
    path: "/el/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___et",
    path: "/et/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___fi",
    path: "/fi/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___hr",
    path: "/hr/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___hu",
    path: "/hu/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___lt",
    path: "/lt/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___lv",
    path: "/lv/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___no",
    path: "/no/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pl",
    path: "/pl/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___pt",
    path: "/pt/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ro",
    path: "/ro/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ru",
    path: "/ru/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sk",
    path: "/sk/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sl",
    path: "/sl/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sr",
    path: "/sr/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___sv",
    path: "/sv/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___tr",
    path: "/tr/account",
    meta: indexOUyDKEkoBlMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___de",
    path: "/de/account/anmelden",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___en",
    path: "/en/account/login",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___nl",
    path: "/nl/account/inlog-formulier",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___fr",
    path: "/fr/account/formulaire-de-connexion",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___it",
    path: "/it/account/modulo-login",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___es",
    path: "/es/account/iniciar-sesi%C3%B3n",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___bg",
    path: "/bg/account/%D0%B2%D0%BF%D0%B8%D1%81%D0%B2%D0%B0%D0%BD%D0%B5-%D0%B1%D0%BB%D0%B0%D0%BD%D0%BA%D0%B0",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___bs",
    path: "/bs/account/forma-za-prijavu",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___cs",
    path: "/cs/account/prihlasovaci-formular",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___da",
    path: "/da/account/login-form",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___el",
    path: "/el/account/%CF%86%CE%BF%CF%81%CE%BC%CE%B1-%CF%83%CF%85%CE%BD%CE%B4%CE%B5%CF%83%CE%B7%CF%82",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___et",
    path: "/et/account/login-vorm",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___fi",
    path: "/fi/account/kirjautumis-lomake",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___hr",
    path: "/hr/account/obrazac-za-prijavu",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___hu",
    path: "/hu/account/bejelentkezesi-urlap",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___lt",
    path: "/lt/account/prisijungimas",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___lv",
    path: "/lv/account/ielogosanas-forma",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___no",
    path: "/no/account/logg-inn-skjema",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___pl",
    path: "/pl/account/formularz-logowania",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___pt",
    path: "/pt/account/formulario-de-login",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___ro",
    path: "/ro/account/formular-conectare",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___ru",
    path: "/ru/account/%D1%84%D0%BE%D1%80%D0%BC%D0%B0-%D0%B2%D1%85%D0%BE%D0%B4%D0%B0-%D0%B2-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D1%83%20",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___sk",
    path: "/sk/account/prihlasovaci-formular",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___sl",
    path: "/sl/account/prijavni-obrazec",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___sr",
    path: "/sr/account/obrazac-prijave",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___sv",
    path: "/sv/account/login-formular",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-login___tr",
    path: "/tr/account/oturum-acma-formu",
    meta: indexpqmq1V3BRkMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/login/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___de",
    path: "/de/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___en",
    path: "/en/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___nl",
    path: "/nl/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___fr",
    path: "/fr/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___it",
    path: "/it/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___es",
    path: "/es/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___bg",
    path: "/bg/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___bs",
    path: "/bs/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___cs",
    path: "/cs/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___da",
    path: "/da/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___el",
    path: "/el/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___et",
    path: "/et/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___fi",
    path: "/fi/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___hr",
    path: "/hr/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___hu",
    path: "/hu/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___lt",
    path: "/lt/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___lv",
    path: "/lv/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___no",
    path: "/no/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___pl",
    path: "/pl/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___pt",
    path: "/pt/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___ro",
    path: "/ro/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___ru",
    path: "/ru/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___sk",
    path: "/sk/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___sl",
    path: "/sl/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___sr",
    path: "/sr/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___sv",
    path: "/sv/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media-identifier___tr",
    path: "/tr/account/media/:identifier()",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___de",
    path: "/de/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___en",
    path: "/en/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___nl",
    path: "/nl/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___fr",
    path: "/fr/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___it",
    path: "/it/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___es",
    path: "/es/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___bg",
    path: "/bg/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___bs",
    path: "/bs/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___cs",
    path: "/cs/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___da",
    path: "/da/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___el",
    path: "/el/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___et",
    path: "/et/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___fi",
    path: "/fi/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___hr",
    path: "/hr/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___hu",
    path: "/hu/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___lt",
    path: "/lt/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___lv",
    path: "/lv/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___no",
    path: "/no/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___pl",
    path: "/pl/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___pt",
    path: "/pt/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___ro",
    path: "/ro/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___ru",
    path: "/ru/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___sk",
    path: "/sk/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___sl",
    path: "/sl/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___sr",
    path: "/sr/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___sv",
    path: "/sv/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-media___tr",
    path: "/tr/account/media",
    meta: indexIUsxSfuaJNMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/media/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___de",
    path: "/de/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___en",
    path: "/en/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___nl",
    path: "/nl/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___fr",
    path: "/fr/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___it",
    path: "/it/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___es",
    path: "/es/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___bg",
    path: "/bg/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___bs",
    path: "/bs/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___cs",
    path: "/cs/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___da",
    path: "/da/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___el",
    path: "/el/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___et",
    path: "/et/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___fi",
    path: "/fi/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___hr",
    path: "/hr/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___hu",
    path: "/hu/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___lt",
    path: "/lt/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___lv",
    path: "/lv/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___no",
    path: "/no/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___pl",
    path: "/pl/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___pt",
    path: "/pt/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___ro",
    path: "/ro/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___ru",
    path: "/ru/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___sk",
    path: "/sk/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___sl",
    path: "/sl/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___sr",
    path: "/sr/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___sv",
    path: "/sv/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-profile___tr",
    path: "/tr/account/profile",
    meta: indexTl6NvlQBi5Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___de",
    path: "/de/account/registrieren",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___en",
    path: "/en/account/register",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___nl",
    path: "/nl/account/registratieformulier",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___fr",
    path: "/fr/account/formulaire-d-inscription",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___it",
    path: "/it/account/modulo-registrazione",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___es",
    path: "/es/account/registro",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___bg",
    path: "/bg/account/%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D1%8F-%D0%B1%D0%BB%D0%B0%D0%BD%D0%BA%D0%B0",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___bs",
    path: "/bs/account/registraciona-forma",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___cs",
    path: "/cs/account/registracni-formular",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___da",
    path: "/da/account/tilmeldings-form",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___el",
    path: "/el/account/%CF%86%CE%BF%CF%81%CE%BC%CE%B1-%CE%B5%CE%B3%CE%B3%CF%81%CE%B1%CF%86%CE%B7%CF%82",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___et",
    path: "/et/account/registreerimise-vorm",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___fi",
    path: "/fi/account/rekisterointi-lomake",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___hr",
    path: "/hr/account/obrazac-za-registraciju",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___hu",
    path: "/hu/account/regisztracios-urlap",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___lt",
    path: "/lt/account/registracija",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___lv",
    path: "/lv/account/registracijas-veidlapa",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___no",
    path: "/no/account/registreringsskjema",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___pl",
    path: "/pl/account/formularz-rejestracyjny",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___pt",
    path: "/pt/account/formulario-de-registro",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___ro",
    path: "/ro/account/formular-inregistrare",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___ru",
    path: "/ru/account/%D1%84%D0%BE%D1%80%D0%BC%D0%B0-%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___sk",
    path: "/sk/account/registracny-formular",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___sl",
    path: "/sl/account/registracija-obrazec",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___sr",
    path: "/sr/account/obrazac-registracije",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___sv",
    path: "/sv/account/registreringsformular",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-register___tr",
    path: "/tr/account/kayit-formu",
    meta: indexX8agzTrVDVMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/register/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___de",
    path: "/de/account/passwort-zuruecksetzen",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___en",
    path: "/en/account/reset-password",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___nl",
    path: "/nl/account/wachtwoord-herstellen",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___fr",
    path: "/fr/account/reinitialiser-mot-de-passe",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___it",
    path: "/it/account/reimposta-password",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___es",
    path: "/es/account/restablecer-contrase%C3%B1a",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___bg",
    path: "/bg/account/%D0%BD%D1%83%D0%BB%D0%B8%D1%80%D0%B0%D0%BD%D0%B5-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D0%B0",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___bs",
    path: "/bs/account/resetuj-lozinku",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___cs",
    path: "/cs/account/obnova-hesla",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___da",
    path: "/da/account/nulstil-adgangskode",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___el",
    path: "/el/account/%CE%B5%CF%80%CE%B1%CE%BD%CE%B1%CF%86%CE%BF%CF%81%CE%B1-%CE%BA%CF%89%CE%B4%CE%B9%CE%BA%CE%BF%CF%85-%CF%80%CF%81%CE%BF%CF%83%CE%B2%CE%B1%CF%83%CE%B7%CF%82",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___et",
    path: "/et/account/lahtesta-salasona",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___fi",
    path: "/fi/account/nollaa-salasana",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___hr",
    path: "/hr/account/ponovno-postavi-lozinku",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___hu",
    path: "/hu/account/uj-jelszo-beallitasa",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___lt",
    path: "/lt/account/nustatyti-slaptazodi-is-naujo",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___lv",
    path: "/lv/account/atjaunot-paroli",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___no",
    path: "/no/account/tilbakestille-passord",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___pl",
    path: "/pl/account/zresetuj-has%C5%82o",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___pt",
    path: "/pt/account/redefinir-senha",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___ro",
    path: "/ro/account/resetare-parola",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___ru",
    path: "/ru/account/%D1%81%D0%B1%D1%80%D0%BE%D1%81-%D0%BF%D0%B0%D1%80%D0%BE%D0%BB%D1%8F",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___sk",
    path: "/sk/account/obnovit-heslo",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___sl",
    path: "/sl/account/ponastavitev-geslo",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___sr",
    path: "/sr/account/resetovanje-lozinke",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___sv",
    path: "/sv/account/aterstall-losenord",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password___tr",
    path: "/tr/account/parolayi-sifirla",
    meta: indexhruc4HfwSsMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reset-password/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___de",
    path: "/de/account/bewertungen",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___en",
    path: "/en/account/reviews",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___nl",
    path: "/nl/account/beoordelingen",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___fr",
    path: "/fr/account/critiques",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___it",
    path: "/it/account/recensioni",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___es",
    path: "/es/account/valoraciones",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___bg",
    path: "/bg/account/%D0%BC%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___bs",
    path: "/bs/account/recenzije",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___cs",
    path: "/cs/account/recenze",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___da",
    path: "/da/account/anmeldelser",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___el",
    path: "/el/account/%CF%83%CF%87%CF%8C%CE%BB%CE%B9%CE%B1",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___et",
    path: "/et/account/%C3%BClevaated",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___fi",
    path: "/fi/account/arvostelut",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___hr",
    path: "/hr/account/recenzije",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___hu",
    path: "/hu/account/v%C3%A9lem%C3%A9nyek",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___lt",
    path: "/lt/account/atsiliepimai",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___lv",
    path: "/lv/account/atsauksmes",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___no",
    path: "/no/account/anmeldelser",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___pl",
    path: "/pl/account/recenzje",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___pt",
    path: "/pt/account/rever",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___ro",
    path: "/ro/account/comentarii",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___ru",
    path: "/ru/account/%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___sk",
    path: "/sk/account/recenzia",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___sl",
    path: "/sl/account/pregledi",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___sr",
    path: "/sr/account/mi%C5%A1ljenja",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___sv",
    path: "/sv/account/recensioner",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reviews___tr",
    path: "/tr/account/yorumlar",
    meta: indexR8OCn2Nh0cMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/account/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___de",
    path: "/de/campingplatz/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___en",
    path: "/en/campsite/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___nl",
    path: "/nl/kampeerplek/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___fr",
    path: "/fr/emplacement-de-camping/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___it",
    path: "/it/campeggio/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___es",
    path: "/es/camping/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___bs",
    path: "/bs/kamp-mjesto/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___cs",
    path: "/cs/kemp/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___da",
    path: "/da/campinggrund/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___et",
    path: "/et/kamping/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___fi",
    path: "/fi/leirintaalue/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___hu",
    path: "/hu/taborhely/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___lt",
    path: "/lt/stovyklaviete/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___lv",
    path: "/lv/karavanu-vieta/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___no",
    path: "/no/campingplass/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___pl",
    path: "/pl/miejsce-kempingowe/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___pt",
    path: "/pt/acampamento/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___ro",
    path: "/ro/loc-de-campare/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___sl",
    path: "/sl/kamp/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___sr",
    path: "/sr/kamp-lokacija/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___sv",
    path: "/sv/taltplats/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier___tr",
    path: "/tr/kamp-alani/:identifier()",
    meta: indexxtNbeGi5dzMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___de",
    path: "/de/campingplatz/:identifier()/anfragen/best%C3%A4tigen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___en",
    path: "/en/campsite/:identifier()/inquire/confirm",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___nl",
    path: "/nl/kampeerplek/:identifier()/informeren/bevestigen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___fr",
    path: "/fr/emplacement-de-camping/:identifier()/renseigner/confirmer",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___it",
    path: "/it/campeggio/:identifier()/chiedere-informazioni/confermare",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___es",
    path: "/es/camping/:identifier()/preguntar/confirmar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%B7%D0%B0%D0%BF%D0%B8%D1%82%D0%B2%D0%B0%D0%BD%D0%B5/%D0%BF%D0%BE%D1%82%D0%B2%D1%8A%D1%80%D0%B6%D0%B4%D0%B0%D0%B2%D0%B0%D0%BD%D0%B5",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___bs",
    path: "/bs/kamp-mjesto/:identifier()/raspitati-se/potvrditi",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___cs",
    path: "/cs/kemp/:identifier()/zeptat-se/potvrdit",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___da",
    path: "/da/campinggrund/:identifier()/forh%C3%B8re/bekr%C3%A6fte",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/%CF%81%CF%89%CF%84%CF%8E/%CE%B5%CF%80%CE%B9%CE%B2%CE%B5%CE%B2%CE%B1%CE%B9%CF%8E%CE%BD%CF%89",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___et",
    path: "/et/kamping/:identifier()/k%C3%BCsima/kinnitage",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___fi",
    path: "/fi/leirintaalue/:identifier()/tiedustella/vahvistaa",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/raspitati/potvr%C4%91uju",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___hu",
    path: "/hu/taborhely/:identifier()/%C3%A9rdekl%C5%91dik/meger%C5%91s%C3%ADt",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___lt",
    path: "/lt/stovyklaviete/:identifier()/teirautis/patvirtinti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___lv",
    path: "/lv/karavanu-vieta/:identifier()/jaut%C4%81t/apstiprin%C4%81t",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___no",
    path: "/no/campingplass/:identifier()/sp%C3%B8rre/bekrefte",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/dowiadywa%C4%87-si%C4%99/potwierdza%C4%87",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___pt",
    path: "/pt/acampamento/:identifier()/investigar/confirme",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___ro",
    path: "/ro/loc-de-campare/:identifier()/%C3%AEntreba/a-confirma",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8/%D0%BF%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/dotazovan%C3%ADm/potvrdit",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___sl",
    path: "/sl/kamp/:identifier()/poizvedovati/potrdite",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___sr",
    path: "/sr/kamp-lokacija/:identifier()/%D0%BF%D0%B8%D1%82%D0%B0%D1%82%D0%B8/%D0%9F%D0%BE%D1%82%D0%B2%D1%80%D0%B4%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___sv",
    path: "/sv/taltplats/:identifier()/fr%C3%A5ga/bekr%C3%A4fta",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire-confirm___tr",
    path: "/tr/kamp-alani/:identifier()/sormak/onaylamak",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___de",
    path: "/de/campingplatz/:identifier()/anfragen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___en",
    path: "/en/campsite/:identifier()/inquire",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___nl",
    path: "/nl/kampeerplek/:identifier()/informeren",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___fr",
    path: "/fr/emplacement-de-camping/:identifier()/renseigner",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___it",
    path: "/it/campeggio/:identifier()/chiedere-informazioni",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___es",
    path: "/es/camping/:identifier()/preguntar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%B7%D0%B0%D0%BF%D0%B8%D1%82%D0%B2%D0%B0%D0%BD%D0%B5",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___bs",
    path: "/bs/kamp-mjesto/:identifier()/raspitati-se",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___cs",
    path: "/cs/kemp/:identifier()/zeptat-se",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___da",
    path: "/da/campinggrund/:identifier()/forh%C3%B8re",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/%CF%81%CF%89%CF%84%CF%8E",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___et",
    path: "/et/kamping/:identifier()/k%C3%BCsima",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___fi",
    path: "/fi/leirintaalue/:identifier()/tiedustella",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/raspitati",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___hu",
    path: "/hu/taborhely/:identifier()/%C3%A9rdekl%C5%91dik",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___lt",
    path: "/lt/stovyklaviete/:identifier()/teirautis",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___lv",
    path: "/lv/karavanu-vieta/:identifier()/jaut%C4%81t",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___no",
    path: "/no/campingplass/:identifier()/sp%C3%B8rre",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/dowiadywa%C4%87-si%C4%99",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___pt",
    path: "/pt/acampamento/:identifier()/investigar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___ro",
    path: "/ro/loc-de-campare/:identifier()/%C3%AEntreba",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D1%81%D0%BF%D1%80%D0%B0%D0%B2%D0%BA%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/dotazovan%C3%ADm",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___sl",
    path: "/sl/kamp/:identifier()/poizvedovati",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___sr",
    path: "/sr/kamp-lokacija/:identifier()/%D0%BF%D0%B8%D1%82%D0%B0%D1%82%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___sv",
    path: "/sv/taltplats/:identifier()/fr%C3%A5ga",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-inquire___tr",
    path: "/tr/kamp-alani/:identifier()/sormak",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/inquire/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___de",
    path: "/de/campingplatz/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___en",
    path: "/en/campsite/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___nl",
    path: "/nl/kampeerplek/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___fr",
    path: "/fr/emplacement-de-camping/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___it",
    path: "/it/campeggio/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___es",
    path: "/es/camping/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___bs",
    path: "/bs/kamp-mjesto/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___cs",
    path: "/cs/kemp/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___da",
    path: "/da/campinggrund/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___et",
    path: "/et/kamping/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___fi",
    path: "/fi/leirintaalue/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___hu",
    path: "/hu/taborhely/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___lt",
    path: "/lt/stovyklaviete/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___lv",
    path: "/lv/karavanu-vieta/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___no",
    path: "/no/campingplass/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___pt",
    path: "/pt/acampamento/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___ro",
    path: "/ro/loc-de-campare/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___sl",
    path: "/sl/kamp/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___sr",
    path: "/sr/kamp-lokacija/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___sv",
    path: "/sv/taltplats/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-media___tr",
    path: "/tr/kamp-alani/:identifier()/media",
    meta: indexNMh63dzoW2Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/media/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___de",
    path: "/de/campingplatz/:identifier()/bewerten/best%C3%A4tigen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___en",
    path: "/en/campsite/:identifier()/rate/confirm",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___nl",
    path: "/nl/kampeerplek/:identifier()/schatten/bevestigen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___fr",
    path: "/fr/emplacement-de-camping/:identifier()/%C3%A9valuer/confirmer",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___it",
    path: "/it/campeggio/:identifier()/valutare/confermare",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___es",
    path: "/es/camping/:identifier()/valorar/confirmar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BE%D1%86%D0%B5%D0%BD%D0%B8/%D0%BF%D0%BE%D1%82%D0%B2%D1%8A%D1%80%D0%B6%D0%B4%D0%B0%D0%B2%D0%B0%D0%BD%D0%B5",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___bs",
    path: "/bs/kamp-mjesto/:identifier()/procijeniti/potvrditi",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___cs",
    path: "/cs/kemp/:identifier()/posoudit/potvrdit",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___da",
    path: "/da/campinggrund/:identifier()/vurdere/bekr%C3%A6fte",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/%CE%B1%CE%BE%CE%B9%CE%BF%CE%BB%CF%8C%CE%B3%CE%B7%CF%83%CE%B7/%CE%B5%CF%80%CE%B9%CE%B2%CE%B5%CE%B2%CE%B1%CE%B9%CF%8E%CE%BD%CF%89",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___et",
    path: "/et/kamping/:identifier()/hindama/kinnitage",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___fi",
    path: "/fi/leirintaalue/:identifier()/arvioida/vahvistaa",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/procijeniti/potvr%C4%91uju",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___hu",
    path: "/hu/taborhely/:identifier()/%C3%A9rt%C3%A9keli/meger%C5%91s%C3%ADt",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___lt",
    path: "/lt/stovyklaviete/:identifier()/%C4%AFvertinti/patvirtinti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___lv",
    path: "/lv/karavanu-vieta/:identifier()/noteikt/apstiprin%C4%81t",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___no",
    path: "/no/campingplass/:identifier()/vurdere/bekrefte",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/oszacowa%C4%87/potwierdza%C4%87",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___pt",
    path: "/pt/acampamento/:identifier()/avaliar/confirme",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___ro",
    path: "/ro/loc-de-campare/:identifier()/evalua/a-confirma",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D1%82%D1%8C/%D0%BF%D0%BE%D0%B4%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/pos%C3%BAdi%C5%A5/potvrdit",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___sl",
    path: "/sl/kamp/:identifier()/oceniti/potrdite",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___sr",
    path: "/sr/kamp-lokacija/:identifier()/proceniti/%D0%9F%D0%BE%D1%82%D0%B2%D1%80%D0%B4%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___sv",
    path: "/sv/taltplats/:identifier()/bed%C3%B6ma/bekr%C3%A4fta",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate-confirm___tr",
    path: "/tr/kamp-alani/:identifier()/belirlemek/onaylamak",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___de",
    path: "/de/campingplatz/:identifier()/bewerten",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___en",
    path: "/en/campsite/:identifier()/rate",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___nl",
    path: "/nl/kampeerplek/:identifier()/schatten",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___fr",
    path: "/fr/emplacement-de-camping/:identifier()/%C3%A9valuer",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___it",
    path: "/it/campeggio/:identifier()/valutare",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___es",
    path: "/es/camping/:identifier()/valorar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BE%D1%86%D0%B5%D0%BD%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___bs",
    path: "/bs/kamp-mjesto/:identifier()/procijeniti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___cs",
    path: "/cs/kemp/:identifier()/posoudit",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___da",
    path: "/da/campinggrund/:identifier()/vurdere",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/%CE%B1%CE%BE%CE%B9%CE%BF%CE%BB%CF%8C%CE%B3%CE%B7%CF%83%CE%B7",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___et",
    path: "/et/kamping/:identifier()/hindama",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___fi",
    path: "/fi/leirintaalue/:identifier()/arvioida",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/procijeniti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___hu",
    path: "/hu/taborhely/:identifier()/%C3%A9rt%C3%A9keli",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___lt",
    path: "/lt/stovyklaviete/:identifier()/%C4%AFvertinti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___lv",
    path: "/lv/karavanu-vieta/:identifier()/noteikt",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___no",
    path: "/no/campingplass/:identifier()/vurdere",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/oszacowa%C4%87",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___pt",
    path: "/pt/acampamento/:identifier()/avaliar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___ro",
    path: "/ro/loc-de-campare/:identifier()/evalua",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D1%82%D1%8C",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/pos%C3%BAdi%C5%A5",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___sl",
    path: "/sl/kamp/:identifier()/oceniti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___sr",
    path: "/sr/kamp-lokacija/:identifier()/proceniti",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___sv",
    path: "/sv/taltplats/:identifier()/bed%C3%B6ma",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rate___tr",
    path: "/tr/kamp-alani/:identifier()/belirlemek",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rate/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___de",
    path: "/de/campingplatz/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___en",
    path: "/en/campsite/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___nl",
    path: "/nl/kampeerplek/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___fr",
    path: "/fr/emplacement-de-camping/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___it",
    path: "/it/campeggio/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___es",
    path: "/es/camping/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___bs",
    path: "/bs/kamp-mjesto/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___cs",
    path: "/cs/kemp/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___da",
    path: "/da/campinggrund/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___et",
    path: "/et/kamping/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___fi",
    path: "/fi/leirintaalue/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___hu",
    path: "/hu/taborhely/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___lt",
    path: "/lt/stovyklaviete/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___lv",
    path: "/lv/karavanu-vieta/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___no",
    path: "/no/campingplass/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___pt",
    path: "/pt/acampamento/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___ro",
    path: "/ro/loc-de-campare/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___sl",
    path: "/sl/kamp/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___sr",
    path: "/sr/kamp-lokacija/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___sv",
    path: "/sv/taltplats/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-rating-widget___tr",
    path: "/tr/kamp-alani/:identifier()/rating-widget",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/rating-widget/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___de",
    path: "/de/campingplatz/:identifier()/bewertungen/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___en",
    path: "/en/campsite/:identifier()/reviews/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___nl",
    path: "/nl/kampeerplek/:identifier()/beoordelingen/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___fr",
    path: "/fr/emplacement-de-camping/:identifier()/critiques/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___it",
    path: "/it/campeggio/:identifier()/recensioni/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___es",
    path: "/es/camping/:identifier()/valoraciones/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BC%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___bs",
    path: "/bs/kamp-mjesto/:identifier()/recenzije/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___cs",
    path: "/cs/kemp/:identifier()/recenze/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___da",
    path: "/da/campinggrund/:identifier()/anmeldelser/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/%CF%83%CF%87%CF%8C%CE%BB%CE%B9%CE%B1/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___et",
    path: "/et/kamping/:identifier()/%C3%BClevaated/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___fi",
    path: "/fi/leirintaalue/:identifier()/arvostelut/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/recenzije/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___hu",
    path: "/hu/taborhely/:identifier()/v%C3%A9lem%C3%A9nyek/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___lt",
    path: "/lt/stovyklaviete/:identifier()/atsiliepimai/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___lv",
    path: "/lv/karavanu-vieta/:identifier()/atsauksmes/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___no",
    path: "/no/campingplass/:identifier()/anmeldelser/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/recenzje/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___pt",
    path: "/pt/acampamento/:identifier()/rever/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___ro",
    path: "/ro/loc-de-campare/:identifier()/comentarii/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/recenzia/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___sl",
    path: "/sl/kamp/:identifier()/pregledi/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___sr",
    path: "/sr/kamp-lokacija/:identifier()/mi%C5%A1ljenja/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___sv",
    path: "/sv/taltplats/:identifier()/recensioner/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews-id___tr",
    path: "/tr/kamp-alani/:identifier()/yorumlar/:id()",
    meta: indexds0VpMgC2kMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___de",
    path: "/de/campingplatz/:identifier()/bewertungen",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___en",
    path: "/en/campsite/:identifier()/reviews",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___nl",
    path: "/nl/kampeerplek/:identifier()/beoordelingen",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___fr",
    path: "/fr/emplacement-de-camping/:identifier()/critiques",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___it",
    path: "/it/campeggio/:identifier()/recensioni",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___es",
    path: "/es/camping/:identifier()/valoraciones",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BC%D0%BD%D0%B5%D0%BD%D0%B8%D1%8F",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___bs",
    path: "/bs/kamp-mjesto/:identifier()/recenzije",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___cs",
    path: "/cs/kemp/:identifier()/recenze",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___da",
    path: "/da/campinggrund/:identifier()/anmeldelser",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/%CF%83%CF%87%CF%8C%CE%BB%CE%B9%CE%B1",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___et",
    path: "/et/kamping/:identifier()/%C3%BClevaated",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___fi",
    path: "/fi/leirintaalue/:identifier()/arvostelut",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/recenzije",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___hu",
    path: "/hu/taborhely/:identifier()/v%C3%A9lem%C3%A9nyek",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___lt",
    path: "/lt/stovyklaviete/:identifier()/atsiliepimai",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___lv",
    path: "/lv/karavanu-vieta/:identifier()/atsauksmes",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___no",
    path: "/no/campingplass/:identifier()/anmeldelser",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/recenzje",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___pt",
    path: "/pt/acampamento/:identifier()/rever",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___ro",
    path: "/ro/loc-de-campare/:identifier()/comentarii",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%BE%D1%82%D0%B7%D1%8B%D0%B2%D1%8B",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/recenzia",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___sl",
    path: "/sl/kamp/:identifier()/pregledi",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___sr",
    path: "/sr/kamp-lokacija/:identifier()/mi%C5%A1ljenja",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___sv",
    path: "/sv/taltplats/:identifier()/recensioner",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-reviews___tr",
    path: "/tr/kamp-alani/:identifier()/yorumlar",
    meta: indexGyuUiDf2fgMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___de",
    path: "/de/campingplatz/:identifier()/hochladen/bilder",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___en",
    path: "/en/campsite/:identifier()/upload/images",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___nl",
    path: "/nl/kampeerplek/:identifier()/uploaden/afbeeldingen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___fr",
    path: "/fr/emplacement-de-camping/:identifier()/t%C3%A9l%C3%A9charger/images",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___it",
    path: "/it/campeggio/:identifier()/upload/immagini",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___es",
    path: "/es/camping/:identifier()/subir/im%C3%A1genes",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BA%D0%B0%D1%87%D0%B2%D0%B0%D0%BD%D0%B5/%D1%81%D0%BD%D0%B8%D0%BC%D0%BA%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___bs",
    path: "/bs/kamp-mjesto/:identifier()/upload/slike",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___cs",
    path: "/cs/kemp/:identifier()/nahr%C3%A1t/obrazy",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___da",
    path: "/da/campinggrund/:identifier()/uploade/billeder",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/upload/%CE%B5%CE%B9%CE%BA%CF%8C%CE%BD%CE%B5%CF%82",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___et",
    path: "/et/kamping/:identifier()/laadi-%C3%BCles/pildid",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___fi",
    path: "/fi/leirintaalue/:identifier()/upload/kuvat",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/uploadati/slike",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___hu",
    path: "/hu/taborhely/:identifier()/upload/k%C3%A9pek",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___lt",
    path: "/lt/stovyklaviete/:identifier()/%C4%AFkelti/paveiksl%C4%97liai",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___lv",
    path: "/lv/karavanu-vieta/:identifier()/aug%C5%A1upiel%C4%81d%C4%93t/bildes",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___no",
    path: "/no/campingplass/:identifier()/laste-opp/bilder",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/przekaza%C4%87-plik/zdj%C4%99cia",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___pt",
    path: "/pt/acampamento/:identifier()/inserir/fotos",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___ro",
    path: "/ro/loc-de-campare/:identifier()/%C3%AEnc%C4%83rcare/poze",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%B7%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B0/%D1%84%D0%BE%D1%82%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%B8",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/upload/fotografie",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___sl",
    path: "/sl/kamp/:identifier()/upload/slike",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___sr",
    path: "/sr/kamp-lokacija/:identifier()/upload/slike",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___sv",
    path: "/sv/taltplats/:identifier()/ladda-upp/bilder",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-images___tr",
    path: "/tr/kamp-alani/:identifier()/y%C3%BCklemek/resimler",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/images/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___de",
    path: "/de/campingplatz/:identifier()/hochladen",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___en",
    path: "/en/campsite/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___nl",
    path: "/nl/kampeerplek/:identifier()/uploaden",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___fr",
    path: "/fr/emplacement-de-camping/:identifier()/t%C3%A9l%C3%A9charger",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___it",
    path: "/it/campeggio/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___es",
    path: "/es/camping/:identifier()/subir",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BA%D0%B0%D1%87%D0%B2%D0%B0%D0%BD%D0%B5",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___bs",
    path: "/bs/kamp-mjesto/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___cs",
    path: "/cs/kemp/:identifier()/nahr%C3%A1t",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___da",
    path: "/da/campinggrund/:identifier()/uploade",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___et",
    path: "/et/kamping/:identifier()/laadi-%C3%BCles",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___fi",
    path: "/fi/leirintaalue/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/uploadati",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___hu",
    path: "/hu/taborhely/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___lt",
    path: "/lt/stovyklaviete/:identifier()/%C4%AFkelti",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___lv",
    path: "/lv/karavanu-vieta/:identifier()/aug%C5%A1upiel%C4%81d%C4%93t",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___no",
    path: "/no/campingplass/:identifier()/laste-opp",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/przekaza%C4%87-plik",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___pt",
    path: "/pt/acampamento/:identifier()/inserir",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___ro",
    path: "/ro/loc-de-campare/:identifier()/%C3%AEnc%C4%83rcare",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%B7%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B0",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___sl",
    path: "/sl/kamp/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___sr",
    path: "/sr/kamp-lokacija/:identifier()/upload",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___sv",
    path: "/sv/taltplats/:identifier()/ladda-upp",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload___tr",
    path: "/tr/kamp-alani/:identifier()/y%C3%BCklemek",
    meta: indexxnV5MLSQcOMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___de",
    path: "/de/campingplatz/:identifier()/hochladen/videos",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___en",
    path: "/en/campsite/:identifier()/upload/videos",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___nl",
    path: "/nl/kampeerplek/:identifier()/uploaden/videos",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___fr",
    path: "/fr/emplacement-de-camping/:identifier()/t%C3%A9l%C3%A9charger/vid%C3%A9os",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___it",
    path: "/it/campeggio/:identifier()/upload/video",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___es",
    path: "/es/camping/:identifier()/subir/v%C3%ADdeos",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80/:identifier()/%D0%BA%D0%B0%D1%87%D0%B2%D0%B0%D0%BD%D0%B5/%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE%D0%BA%D0%BB%D0%B8%D0%BF%D0%BE%D0%B2%D0%B5",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___bs",
    path: "/bs/kamp-mjesto/:identifier()/upload/video",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___cs",
    path: "/cs/kemp/:identifier()/nahr%C3%A1t/videa",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___da",
    path: "/da/campinggrund/:identifier()/uploade/videoer",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA/:identifier()/upload/%CE%B2%CE%AF%CE%BD%CF%84%CE%B5%CE%BF",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___et",
    path: "/et/kamping/:identifier()/laadi-%C3%BCles/videod",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___fi",
    path: "/fi/leirintaalue/:identifier()/upload/videot",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___hr",
    path: "/hr/mjesto-za-kampiranje/:identifier()/uploadati/video",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___hu",
    path: "/hu/taborhely/:identifier()/upload/vide%C3%B3k",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___lt",
    path: "/lt/stovyklaviete/:identifier()/%C4%AFkelti/vaizdo-%C4%AFra%C5%A1ai",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___lv",
    path: "/lv/karavanu-vieta/:identifier()/aug%C5%A1upiel%C4%81d%C4%93t/video",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___no",
    path: "/no/campingplass/:identifier()/laste-opp/videoer",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___pl",
    path: "/pl/miejsce-kempingowe/:identifier()/przekaza%C4%87-plik/filmy",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___pt",
    path: "/pt/acampamento/:identifier()/inserir/v%C3%ADdeos",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___ro",
    path: "/ro/loc-de-campare/:identifier()/%C3%AEnc%C4%83rcare/videoclipuri",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C/:identifier()/%D0%B7%D0%B0%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B0/%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___sk",
    path: "/sk/miesto-na-kempovanie/:identifier()/upload/vide%C3%A1",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___sl",
    path: "/sl/kamp/:identifier()/upload/videi",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___sr",
    path: "/sr/kamp-lokacija/:identifier()/upload/video",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___sv",
    path: "/sv/taltplats/:identifier()/ladda-upp/videor",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite-identifier-upload-videos___tr",
    path: "/tr/kamp-alani/:identifier()/y%C3%BCklemek/videolar",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/[identifier]/upload/videos/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___de",
    path: "/de/campingplatz",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___en",
    path: "/en/campsite",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___nl",
    path: "/nl/kampeerplek",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___fr",
    path: "/fr/emplacement-de-camping",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___it",
    path: "/it/campeggio",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___es",
    path: "/es/camping",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___bg",
    path: "/bg/%D0%BB%D0%B0%D0%B3%D0%B5%D1%80",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___bs",
    path: "/bs/kamp-mjesto",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___cs",
    path: "/cs/kemp",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___da",
    path: "/da/campinggrund",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___el",
    path: "/el/%CE%B8%CE%B5%CF%83%CE%B7-%CE%BA%CE%B1%CE%BC%CF%80%CE%B9%CE%BD%CE%B3%CE%BA",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___et",
    path: "/et/kamping",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___fi",
    path: "/fi/leirintaalue",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___hr",
    path: "/hr/mjesto-za-kampiranje",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___hu",
    path: "/hu/taborhely",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___lt",
    path: "/lt/stovyklaviete",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___lv",
    path: "/lv/karavanu-vieta",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___no",
    path: "/no/campingplass",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___pl",
    path: "/pl/miejsce-kempingowe",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___pt",
    path: "/pt/acampamento",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___ro",
    path: "/ro/loc-de-campare",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___ru",
    path: "/ru/%D0%BA%D0%B5%D0%BC%D0%BF%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B0%D0%B3%D0%B5%D1%80%D1%8C",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___sk",
    path: "/sk/miesto-na-kempovanie",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___sl",
    path: "/sl/kamp",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___sr",
    path: "/sr/kamp-lokacija",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___sv",
    path: "/sv/taltplats",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "campsite___tr",
    path: "/tr/kamp-alani",
    meta: indexqRFmcmK0FaMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/campsite/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___de",
    path: "/de/stadt/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___en",
    path: "/en/city/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___nl",
    path: "/nl/stad/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___fr",
    path: "/fr/ville/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___it",
    path: "/it/citt%C3%A0/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___es",
    path: "/es/ciudad/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___bg",
    path: "/bg/%D0%B3%D1%80%D0%B0%D0%B4/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___bs",
    path: "/bs/grad/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___cs",
    path: "/cs/grad/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___da",
    path: "/da/by/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___el",
    path: "/el/%CF%80%CF%8C%CE%BB%CE%B7/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___et",
    path: "/et/linn/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___fi",
    path: "/fi/kaupunki/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___hr",
    path: "/hr/grad/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___hu",
    path: "/hu/v%C3%A1ros/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___lt",
    path: "/lt/miestas/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___lv",
    path: "/lv/pils%C4%93ta/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___no",
    path: "/no/by/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___pl",
    path: "/pl/miasto/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___pt",
    path: "/pt/cidade/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___ro",
    path: "/ro/ora%C8%99/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___ru",
    path: "/ru/%D0%B3%D0%BE%D1%80%D0%BE%D0%B4/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___sk",
    path: "/sk/mesto/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___sl",
    path: "/sl/mesto/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___sr",
    path: "/sr/%D0%B3%D1%80%D0%B0%D0%B4/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___sv",
    path: "/sv/stad/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "city-citySlug___tr",
    path: "/tr/kent/:citySlug()",
    meta: index7vCFUXXTDQMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/city/[citySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___de",
    path: "/de/land/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___en",
    path: "/en/country/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___nl",
    path: "/nl/land/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___fr",
    path: "/fr/pays/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___it",
    path: "/it/paese/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___es",
    path: "/es/pa%C3%ADs/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___bg",
    path: "/bg/%D0%B4%D1%8A%D1%80%D0%B6%D0%B0%D0%B2%D0%B0/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___bs",
    path: "/bs/drzava/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___cs",
    path: "/cs/zeme/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___da",
    path: "/da/land/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___el",
    path: "/el/%CF%87%CF%89%CF%81%CE%B1/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___et",
    path: "/et/riik/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___fi",
    path: "/fi/maa/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___hr",
    path: "/hr/zemlja/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___hu",
    path: "/hu/orszag/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___lt",
    path: "/lt/salis/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___lv",
    path: "/lv/valsts/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___no",
    path: "/no/land/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___pl",
    path: "/pl/kraj/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___pt",
    path: "/pt/pais/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___ro",
    path: "/ro/tara/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___ru",
    path: "/ru/%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B0/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___sk",
    path: "/sk/krajina/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___sl",
    path: "/sl/drzava/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___sr",
    path: "/sr/zemlja/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___sv",
    path: "/sv/land/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug-federalStateSlug___tr",
    path: "/tr/ulke/:countrySlug()/:federalStateSlug()",
    meta: indexIxaNJTz7CPMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/[federalStateSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___de",
    path: "/de/land/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___en",
    path: "/en/country/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___nl",
    path: "/nl/land/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___fr",
    path: "/fr/pays/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___it",
    path: "/it/paese/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___es",
    path: "/es/pa%C3%ADs/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___bg",
    path: "/bg/%D0%B4%D1%8A%D1%80%D0%B6%D0%B0%D0%B2%D0%B0/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___bs",
    path: "/bs/drzava/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___cs",
    path: "/cs/zeme/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___da",
    path: "/da/land/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___el",
    path: "/el/%CF%87%CF%89%CF%81%CE%B1/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___et",
    path: "/et/riik/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___fi",
    path: "/fi/maa/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___hr",
    path: "/hr/zemlja/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___hu",
    path: "/hu/orszag/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___lt",
    path: "/lt/salis/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___lv",
    path: "/lv/valsts/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___no",
    path: "/no/land/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___pl",
    path: "/pl/kraj/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___pt",
    path: "/pt/pais/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___ro",
    path: "/ro/tara/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___ru",
    path: "/ru/%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B0/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___sk",
    path: "/sk/krajina/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___sl",
    path: "/sl/drzava/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___sr",
    path: "/sr/zemlja/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___sv",
    path: "/sv/land/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country-countrySlug___tr",
    path: "/tr/ulke/:countrySlug()",
    meta: indexxFqNkPV5JcMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/[countrySlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "country___de",
    path: "/de/land",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___en",
    path: "/en/country",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___nl",
    path: "/nl/land",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___fr",
    path: "/fr/pays",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___it",
    path: "/it/paese",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___es",
    path: "/es/pa%C3%ADs",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___bg",
    path: "/bg/%D0%B4%D1%8A%D1%80%D0%B6%D0%B0%D0%B2%D0%B0",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___bs",
    path: "/bs/drzava",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___cs",
    path: "/cs/zeme",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___da",
    path: "/da/land",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___el",
    path: "/el/%CF%87%CF%89%CF%81%CE%B1",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___et",
    path: "/et/riik",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___fi",
    path: "/fi/maa",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___hr",
    path: "/hr/zemlja",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___hu",
    path: "/hu/orszag",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___lt",
    path: "/lt/salis",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___lv",
    path: "/lv/valsts",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___no",
    path: "/no/land",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___pl",
    path: "/pl/kraj",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___pt",
    path: "/pt/pais",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___ro",
    path: "/ro/tara",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___ru",
    path: "/ru/%D1%81%D1%82%D1%80%D0%B0%D0%BD%D0%B0",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___sk",
    path: "/sk/krajina",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___sl",
    path: "/sl/drzava",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___sr",
    path: "/sr/zemlja",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___sv",
    path: "/sv/land",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "country___tr",
    path: "/tr/ulke",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/country/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/it",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___bg",
    path: "/bg",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___bs",
    path: "/bs",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cs",
    path: "/cs",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___da",
    path: "/da",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___el",
    path: "/el",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___et",
    path: "/et",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fi",
    path: "/fi",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hr",
    path: "/hr",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___hu",
    path: "/hu",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___lt",
    path: "/lt",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___lv",
    path: "/lv",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___no",
    path: "/no",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pl",
    path: "/pl",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ro",
    path: "/ro",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sk",
    path: "/sk",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sl",
    path: "/sl",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sr",
    path: "/sr",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___sv",
    path: "/sv",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___tr",
    path: "/tr",
    meta: indexVN93EJK4S8Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "info-all___de",
    path: "/de/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___en",
    path: "/en/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___nl",
    path: "/nl/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___fr",
    path: "/fr/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___it",
    path: "/it/informazioni/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___es",
    path: "/es/informaci%C3%B3n/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___bg",
    path: "/bg/%D0%B8%D0%BD%D1%84%D0%BE/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___bs",
    path: "/bs/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___cs",
    path: "/cs/informace/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___da",
    path: "/da/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___el",
    path: "/el/%CF%80%CE%BB%CE%B7%CF%81%CE%BF%CF%86%CE%BF%CF%81%CE%AF%CE%B5%CF%82/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___et",
    path: "/et/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___fi",
    path: "/fi/tiedot/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___hr",
    path: "/hr/informacije/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___hu",
    path: "/hu/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___lt",
    path: "/lt/informacija/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___lv",
    path: "/lv/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___no",
    path: "/no/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___pl",
    path: "/pl/informacje/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___pt",
    path: "/pt/informa%C3%A7%C3%A3o/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___ro",
    path: "/ro/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___ru",
    path: "/ru/%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___sk",
    path: "/sk/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___sl",
    path: "/sl/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___sr",
    path: "/sr/%D0%B8%D0%BD%D1%84%D0%BE/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___sv",
    path: "/sv/info/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info-all___tr",
    path: "/tr/bilgi/:all(.*)*",
    meta: _91_46_46_46all_93AF9oPoK06oMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/[...all].vue").then(m => m.default || m)
  },
  {
    name: "info___de",
    path: "/de/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___en",
    path: "/en/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___nl",
    path: "/nl/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___fr",
    path: "/fr/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___it",
    path: "/it/informazioni",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___es",
    path: "/es/informaci%C3%B3n",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___bg",
    path: "/bg/%D0%B8%D0%BD%D1%84%D0%BE",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___bs",
    path: "/bs/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___cs",
    path: "/cs/informace",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___da",
    path: "/da/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___el",
    path: "/el/%CF%80%CE%BB%CE%B7%CF%81%CE%BF%CF%86%CE%BF%CF%81%CE%AF%CE%B5%CF%82",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___et",
    path: "/et/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___fi",
    path: "/fi/tiedot",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___hr",
    path: "/hr/informacije",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___hu",
    path: "/hu/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___lt",
    path: "/lt/informacija",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___lv",
    path: "/lv/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___no",
    path: "/no/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___pl",
    path: "/pl/informacje",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___pt",
    path: "/pt/informa%C3%A7%C3%A3o",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___ro",
    path: "/ro/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___ru",
    path: "/ru/%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___sk",
    path: "/sk/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___sl",
    path: "/sl/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___sr",
    path: "/sr/%D0%B8%D0%BD%D1%84%D0%BE",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___sv",
    path: "/sv/info",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "info___tr",
    path: "/tr/bilgi",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___de",
    path: "/de/beliebte-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___en",
    path: "/en/popular-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___nl",
    path: "/nl/populaire-landen/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___fr",
    path: "/fr/region-populaire/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___it",
    path: "/it/regione-popolare/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___es",
    path: "/es/regi%C3%B3n-popular/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___bg",
    path: "/bg/%D0%BF%D0%BE%D0%BF%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D0%B0-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___bs",
    path: "/bs/popularan-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___cs",
    path: "/cs/oblibene-regiony/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___da",
    path: "/da/popul%C3%A6r-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___el",
    path: "/el/%CE%B4%CE%B7%CE%BC%CE%BF%CF%86%CE%B9%CE%BB%CE%B7%CF%82-%CF%80%CE%B5%CF%81%CE%B9%CE%BF%CF%87%CE%B7/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___et",
    path: "/et/populaarne-piirkond/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___fi",
    path: "/fi/suosittu-alue/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___hr",
    path: "/hr/popularna-regija/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___hu",
    path: "/hu/nepszeru-regio/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___lt",
    path: "/lt/populiarus-regionas/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___lv",
    path: "/lv/populari-regioni/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___no",
    path: "/no/populaer-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___pl",
    path: "/pl/popularny-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___pt",
    path: "/pt/regiao-popular/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___ro",
    path: "/ro/regiune-populara/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___ru",
    path: "/ru/%D0%BF%D0%BE%D0%BF%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D1%8B%D0%B8-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___sk",
    path: "/sk/popularna-oblast/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___sl",
    path: "/sl/priljubljena-regija/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___sr",
    path: "/sr/popularan-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___sv",
    path: "/sv/popular-region/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion-regionSlug___tr",
    path: "/tr/populer-bolge/:regionSlug()",
    meta: indexnjaRq1UEOhMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/[regionSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___de",
    path: "/de/beliebte-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___en",
    path: "/en/popular-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___nl",
    path: "/nl/populaire-landen",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___fr",
    path: "/fr/region-populaire",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___it",
    path: "/it/regione-popolare",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___es",
    path: "/es/regi%C3%B3n-popular",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___bg",
    path: "/bg/%D0%BF%D0%BE%D0%BF%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D0%B0-%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___bs",
    path: "/bs/popularan-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___cs",
    path: "/cs/oblibene-regiony",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___da",
    path: "/da/popul%C3%A6r-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___el",
    path: "/el/%CE%B4%CE%B7%CE%BC%CE%BF%CF%86%CE%B9%CE%BB%CE%B7%CF%82-%CF%80%CE%B5%CF%81%CE%B9%CE%BF%CF%87%CE%B7",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___et",
    path: "/et/populaarne-piirkond",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___fi",
    path: "/fi/suosittu-alue",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___hr",
    path: "/hr/popularna-regija",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___hu",
    path: "/hu/nepszeru-regio",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___lt",
    path: "/lt/populiarus-regionas",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___lv",
    path: "/lv/populari-regioni",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___no",
    path: "/no/populaer-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___pl",
    path: "/pl/popularny-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___pt",
    path: "/pt/regiao-popular",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___ro",
    path: "/ro/regiune-populara",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___ru",
    path: "/ru/%D0%BF%D0%BE%D0%BF%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D1%8B%D0%B8-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___sk",
    path: "/sk/popularna-oblast",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___sl",
    path: "/sl/priljubljena-regija",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___sr",
    path: "/sr/popularan-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___sv",
    path: "/sv/popular-region",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "popularRegion___tr",
    path: "/tr/populer-bolge",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/popularRegion/index.vue").then(m => m.default || m)
  },
  {
    name: "sea-all___de",
    path: "/de/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___en",
    path: "/en/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___nl",
    path: "/nl/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___fr",
    path: "/fr/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___it",
    path: "/it/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___es",
    path: "/es/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___bg",
    path: "/bg/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___bs",
    path: "/bs/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___cs",
    path: "/cs/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___da",
    path: "/da/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___el",
    path: "/el/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___et",
    path: "/et/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___fi",
    path: "/fi/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___hr",
    path: "/hr/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___hu",
    path: "/hu/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___lt",
    path: "/lt/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___lv",
    path: "/lv/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___no",
    path: "/no/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___pl",
    path: "/pl/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___pt",
    path: "/pt/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___ro",
    path: "/ro/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___ru",
    path: "/ru/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___sk",
    path: "/sk/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___sl",
    path: "/sl/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___sr",
    path: "/sr/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___sv",
    path: "/sv/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "sea-all___tr",
    path: "/tr/sea/:all(.*)*",
    meta: _91_46_46_46all_93lfvzZJVF2AMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/sea/[...all].vue").then(m => m.default || m)
  },
  {
    name: "search___de",
    path: "/de/suche",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___nl",
    path: "/nl/zoeken",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___fr",
    path: "/fr/recherche",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___it",
    path: "/it/cerca",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___es",
    path: "/es/buscar",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___bg",
    path: "/bg/%D1%82%D1%8A%D1%80%D1%81%D0%B5%D0%BD%D0%B5",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___bs",
    path: "/bs/trazi",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___cs",
    path: "/cs/hledat",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___da",
    path: "/da/s%C3%B8g",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___el",
    path: "/el/%CE%B1%CE%BD%CE%B1%CE%B6%CE%B7%CF%84%CE%B7%CF%83%CE%B7",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___et",
    path: "/et/otsing",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___fi",
    path: "/fi/haku",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___hr",
    path: "/hr/trazi",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___lt",
    path: "/lt/paieska",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___lv",
    path: "/lv/meklet",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___no",
    path: "/no/soek",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___pl",
    path: "/pl/szukaj",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___pt",
    path: "/pt/procurar",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___ro",
    path: "/ro/cautare",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___ru",
    path: "/ru/%D0%BF%D0%BE%D0%B8%D1%81%D0%BA",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___sk",
    path: "/sk/vyhladavanie",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___sl",
    path: "/sl/isci",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___sr",
    path: "/sr/pretraga",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___sv",
    path: "/sv/sok",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "search___tr",
    path: "/tr/ara",
    meta: indexgg73T1N1MHMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___de",
    path: "/de/auf-karte-suchen",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___en",
    path: "/en/search-on-map",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___nl",
    path: "/nl/zoek-op-kaart",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___fr",
    path: "/fr/recherche-carte",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___it",
    path: "/it/cerca-mappa",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___es",
    path: "/es/buscar-en-el-mapa",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___bg",
    path: "/bg/%D1%82%D1%8A%D1%80%D1%81%D0%B5%D0%BD%D0%B5-%D0%BA%D0%B0%D1%80%D1%82%D0%B0",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___bs",
    path: "/bs/mapa-pretrage",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___cs",
    path: "/cs/vyhledavaci-mapa",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___da",
    path: "/da/s%C3%B8ge-kort",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___el",
    path: "/el/%CE%B1%CE%BD%CE%B1%CE%B6%CE%B7%CF%84%CE%B7%CF%83%CE%B7-%CF%87%CE%B1%CF%81%CF%84%CE%B7",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___et",
    path: "/et/otsingu-kaart",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___fi",
    path: "/fi/haku-kartta",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___hr",
    path: "/hr/mapa-pretrage",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___hu",
    path: "/hu/kereses-terkepen",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___lt",
    path: "/lt/ieskoti-zemelapyje",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___lv",
    path: "/lv/mekle-karti",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___no",
    path: "/no/soek-kart",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___pl",
    path: "/pl/mapa-wyszukiwania",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___pt",
    path: "/pt/mapa-de-pesquisa",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___ro",
    path: "/ro/cautare-harta",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___ru",
    path: "/ru/%D0%BF%D0%BE%D0%B8%D1%81%D0%BA-%D0%BD%D0%B0-%D0%BA%D0%B0%D1%80%D1%82%D0%B5",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___sk",
    path: "/sk/hladat-na-mape",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___sl",
    path: "/sl/iskanje-zemljevid",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___sr",
    path: "/sr/pretraga-mape",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___sv",
    path: "/sv/sok-karta",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "searchMap___tr",
    path: "/tr/haritada-ara",
    meta: indexrCKlJClxxIMeta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/searchMap/index.vue").then(m => m.default || m)
  },
  {
    name: "topic-all___de",
    path: "/de/thema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___en",
    path: "/en/topic/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___nl",
    path: "/nl/onderwerp/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___fr",
    path: "/fr/sujet/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___it",
    path: "/it/argomento/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___es",
    path: "/es/tema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___bg",
    path: "/bg/%D1%82%D0%B5%D0%BC%D0%B0/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___bs",
    path: "/bs/temu/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___cs",
    path: "/cs/t%C3%A9ma/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___da",
    path: "/da/emne/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___el",
    path: "/el/%CE%B8%CE%AD%CE%BC%CE%B1/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___et",
    path: "/et/teema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___fi",
    path: "/fi/aihe/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___hr",
    path: "/hr/tema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___hu",
    path: "/hu/t%C3%A9ma/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___lt",
    path: "/lt/tema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___lv",
    path: "/lv/temats/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___no",
    path: "/no/emne/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___pl",
    path: "/pl/temat/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___pt",
    path: "/pt/tema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___ro",
    path: "/ro/subiect/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___ru",
    path: "/ru/%D1%82%D0%B5%D0%BC%D0%B0/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___sk",
    path: "/sk/t%C3%A9ma/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___sl",
    path: "/sl/tema/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___sr",
    path: "/sr/%D1%82%D0%B5%D0%BC%D0%B0/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___sv",
    path: "/sv/%C3%A4mne/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic-all___tr",
    path: "/tr/konu/:all(.*)*",
    meta: _91_46_46_46all_93IlrBS9zz52Meta || {},
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/[...all].vue").then(m => m.default || m)
  },
  {
    name: "topic___de",
    path: "/de/thema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___en",
    path: "/en/topic",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___nl",
    path: "/nl/onderwerp",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___fr",
    path: "/fr/sujet",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___it",
    path: "/it/argomento",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___es",
    path: "/es/tema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___bg",
    path: "/bg/%D1%82%D0%B5%D0%BC%D0%B0",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___bs",
    path: "/bs/temu",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___cs",
    path: "/cs/t%C3%A9ma",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___da",
    path: "/da/emne",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___el",
    path: "/el/%CE%B8%CE%AD%CE%BC%CE%B1",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___et",
    path: "/et/teema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___fi",
    path: "/fi/aihe",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___hr",
    path: "/hr/tema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___hu",
    path: "/hu/t%C3%A9ma",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___lt",
    path: "/lt/tema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___lv",
    path: "/lv/temats",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___no",
    path: "/no/emne",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___pl",
    path: "/pl/temat",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___pt",
    path: "/pt/tema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___ro",
    path: "/ro/subiect",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___ru",
    path: "/ru/%D1%82%D0%B5%D0%BC%D0%B0",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___sk",
    path: "/sk/t%C3%A9ma",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___sl",
    path: "/sl/tema",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___sr",
    path: "/sr/%D1%82%D0%B5%D0%BC%D0%B0",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___sv",
    path: "/sv/%C3%A4mne",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  },
  {
    name: "topic___tr",
    path: "/tr/konu",
    component: () => import("/builds/campinginfo/civ2-nuxt-3/src/pages/topic/index.vue").then(m => m.default || m)
  }
]