import useFetchService from '@/composables/useFetchService';

export default function useSpService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl, version: 2 });

  /********************
   * FUNCTIONS         *
   ********************/
  function ti(lang: string, params: any) {
    return fetchService.get(`/sp/`, lang, { params });
  }

  return {
    ti,
  };
}
