import useFetchService from '@/composables/useFetchService';

export default function useBootstrapService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl, version: 3 });

  /********************
   * FUNCTIONS         *
   ********************/
  function getBootstrap(lang: string) {
    return fetchService.get(`/bootstrap/`, lang);
  }

  return {
    getBootstrap,
  };
}
