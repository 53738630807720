import validate from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45mixed_45route_45translations_45global from "/builds/campinginfo/civ2-nuxt-3/src/middleware/01.mixed-route-translations.global.ts";
import _02_45trailing_45slash_45redirect_45global from "/builds/campinginfo/civ2-nuxt-3/src/middleware/02.trailing-slash-redirect.global.ts";
import _03_45headers_45global from "/builds/campinginfo/civ2-nuxt-3/src/middleware/03.headers.global.ts";
import _04_45ads_45global from "/builds/campinginfo/civ2-nuxt-3/src/middleware/04.ads.global.ts";
import _05_45page_45is_45gone_45global from "/builds/campinginfo/civ2-nuxt-3/src/middleware/05.page-is-gone.global.ts";
import _07_45dyndates_45global from "/builds/campinginfo/civ2-nuxt-3/src/middleware/07.dyndates.global.ts";
import manifest_45route_45rule from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45mixed_45route_45translations_45global,
  _02_45trailing_45slash_45redirect_45global,
  _03_45headers_45global,
  _04_45ads_45global,
  _05_45page_45is_45gone_45global,
  _07_45dyndates_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "dynamic-page-redirect": () => import("/builds/campinginfo/civ2-nuxt-3/src/middleware/dynamic-page-redirect.ts"),
  "location-translations-redirect": () => import("/builds/campinginfo/civ2-nuxt-3/src/middleware/location-translations-redirect.ts"),
  "numeric-redirect": () => import("/builds/campinginfo/civ2-nuxt-3/src/middleware/numeric-redirect.ts")
}