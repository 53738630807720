import { defineStore, acceptHMRUpdate } from 'pinia';
import { equals } from 'ramda';

export const useCampsiteStore = defineStore('campsite', () => {
  let cachedAccommodationQuery: any = null;
  let cachedAccommodationSlug: string | null = null;
  let cachedAvailabilityQuery: any = null;
  let cachedAvailabilitySlug: string | null = null;

  /********************
   * COMPOSITIONS      *
   ********************/
  const campsiteService = useCampsiteService();
  const searchStore = useSearchStore();
  const searchService = useSearchService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  /********************
   * RECOMMENDATION    *
   ********************/
  const recommendations = ref(null);
  const recommendationsBottom = computed(() => {
    return recommendations.value?.recommendations_2;
  });
  const recommendationsTop = computed(() => {
    return recommendations.value?.recommendations;
  });

  async function getRecommendations({ slug, lang }: { slug: string; lang: string }) {
    setStatusLoading('getRecommendations');

    try {
      const response = await campsiteService.getRecommendations(slug, lang);
      recommendations.value = response;
      setStatusSuccess('getRecommendations');
      return response;
    } catch (error) {
      setStatusError({ name: 'getRecommendations', error });
      return null;
    }
  }

  /********************
   * CAMPSITE          *
   ********************/
  const campsite = ref(null);
  const activities = computed(() => {
    return campsite.value?.ideal_activities;
  });

  async function getCampsite({ slug, lang }: { slug: string; lang: string }) {
    setStatusLoading('getCampsite');
    searchStore.location = null;

    campsite.value = null;
    setAccommodationDetails({ id: null });

    const params = { client: 'web' };
    if (import.meta.server) {
      /*
      #######################################
      ### t = tracking mode               ###
      #######################################
      TRACKING_MODE_NORMAL = '0'
      TRACKING_MODE_ONLY_TRACKING = '1'
      TRACKING_MODE_NO_TRACKING = '2'
      */
      params.t = '2';
    }

    try {
      const response = await searchService.getCampsite(slug, lang, params);
      campsite.value = response;
      const location = {
        country: response.meta.country,
        federal_state: response.meta.federal_state,
      };

      if (response.regions?.length) {
        location.region = response.regions[0];
      }

      searchStore.location = location;
      setStatusSuccess('getCampsite');
      return response;
    } catch (error) {
      campsite.value = null;
      setStatusError({ name: 'getCampsite', error });
      throw error;
    }
  }

  function clearCampsite() {
    campsite.value = null;
  }

  /********************
   * AVAILABILITIES    *
   ********************/
  const availabilities = ref(null);
  const availabilitiesCurrentPage = ref(1);
  const availabilitiesResultLimit = ref(15);
  const availabilitiesTotalPages = computed(() => {
    if (!availabilities.value || availabilities.value.length <= 0) {
      return 0;
    }
    return Math.max(1, Math.ceil(availabilities.value.length / availabilitiesResultLimit.value));
  });

  async function getLiveAvailabilities({ slug, lang, query }) {
    // paging is done frontend wise, therefore if only the offset
    // has changed, we set the new page and do not trigger the endpoint
    const tempQuery = { ...query };
    delete tempQuery.offset;

    if (!query.offset) {
      query.offset = 0;
    }
    availabilitiesCurrentPage.value = parseInt(query.offset) / availabilitiesResultLimit.value + 1;

    if (equals(cachedAvailabilityQuery, tempQuery) && cachedAvailabilitySlug === slug) {
      return;
    }

    setStatusLoading('getLiveAvailabilities');
    availabilities.value = null;

    cachedAvailabilityQuery = tempQuery;
    cachedAvailabilitySlug = slug;

    try {
      const response = await searchService.getLiveAvailabilities(slug, lang, query);
      const data = sortAccommodationsByType(response);
      availabilities.value = data;
      setStatusSuccess('getLiveAvailabilities');
      return data;
    } catch (error) {
      availabilities.value = null;
      setStatusError({ name: 'getLiveAvailabilities', error });
      return null;
    }
  }

  async function getClosestAvailableCampsites({ slug, lang, query }) {
    setStatusLoading('getClosestAvailableCampsites');

    try {
      const response = await searchService.getClosestAvailableCampsites(slug, lang, query);
      setStatusSuccess('getClosestAvailableCampsites');
      return response;
    } catch (error) {
      setStatusError({ name: 'getClosestAvailableCampsites', error });
      return null;
    }
  }

  /********************
   * ACCOMMODATION     *
   ********************/
  const accommodationDetails = ref(null);
  const accommodations = ref(null);
  const accommodationsCurrentPage = ref(1);
  const accommodationsResultLimit = ref(15);
  const accommodationsTotalPages = computed(() => {
    if (!accommodations.value || accommodations.value.length <= 0) {
      return 0;
    }
    return Math.max(1, Math.ceil(accommodations.value.length / accommodationsResultLimit.value));
  });

  const sortAccommodationsByType = (accommodations) => {
    return accommodations.sort((a, b) => {
      const ta = a.acco_type;
      const tb = b.acco_type;

      if (ta === tb) {
        return 0;
      }
      if (ta === 'pitch') {
        return -1;
      }
      return 1;
    });
  };

  async function getAccommodations({ slug, lang, query }: { slug: string; lang: string; query: any }) {
    // paging is done frontend wise, therefore if only the offset
    // has changed, we set the new page and do not trigger the endpoint
    const tempQuery = { ...query };
    delete tempQuery.offset;

    if (!query.offset) {
      query.offset = 0;
    }
    accommodationsCurrentPage.value = parseInt(query.offset) / accommodationsResultLimit.value + 1;

    if (equals(cachedAccommodationQuery, tempQuery) && cachedAccommodationSlug === slug) {
      return;
    }

    setStatusLoading('getAccommodations');
    accommodations.value = null;

    cachedAccommodationQuery = tempQuery;
    cachedAccommodationSlug = slug;

    try {
      const response = await searchService.getAccommodations(slug, lang);
      let data;
      if (query.acco_type && (typeof query.acco_type === 'string' || query.acco_type instanceof String)) {
        data = response.filter(ele => ele.acco_type === query.acco_type);
      } else {
        data = sortAccommodationsByType(response);
      }
      accommodations.value = data;
      setStatusSuccess('getAccommodations');
      return data;
    } catch (error) {
      accommodations.value = null;
      setStatusError({ name: 'getAccommodations', error });
      return null;
    }
  }

  async function getAccommodationDetails({ slug, id, lang }) {
    setStatusLoading('getAccommodationDetails');

    try {
      const response = await searchService.getAccommodations(`${slug}/${id}`, lang);
      setAccommodationDetails({ id, payload: response });
      setStatusSuccess('getAccommodationDetails');
      return response;
    } catch (error) {
      setAccommodationDetails({ id: null });
      setStatusError({ name: 'getAccommodationDetails', error });
      return null;
    }
  }

  function setAccommodationDetails({ id, payload }: { id: string | null; payload?: any }) {
    if (!id) {
      accommodationDetails.value = null;
      return;
    }

    const temp = { ...accommodationDetails.value };
    temp[id] = payload;
    accommodationDetails.value = temp;
  }

  return {
    accommodationDetails,
    accommodations,
    accommodationsCurrentPage,
    accommodationsResultLimit,
    accommodationsTotalPages,
    activities,
    availabilities,
    availabilitiesCurrentPage,
    availabilitiesResultLimit,
    availabilitiesTotalPages,
    campsite,
    clearCampsite,
    getAccommodationDetails,
    getAccommodations,
    getCampsite,
    getClosestAvailableCampsites,
    getLiveAvailabilities,
    getRecommendations,
    recommendationsBottom,
    recommendationsTop,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCampsiteStore, import.meta.hot));
}
