import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const router = useRouter();
    const runtimeConfig = useRuntimeConfig();
    const sampleRate = runtimeConfig.public.envName === 'prod' ? 0.001 : 1.0;

    if (!runtimeConfig.public.sentry.dsn) {
      return;
    }

    const config = {
      app: nuxtApp.vueApp,
      dsn: runtimeConfig.public.sentry.dsn,
      environment: runtimeConfig.public.envName,
      debug: false,
      logErrors: true,
      ignoreErrors: [
        'undefined',
        '<unknown>',
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'ResizeObserver loop limit exceeded',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        'Uncaught TypeError: a[b].target.className.indexOf is not a function',
        'TypeError: a[b].target.className.indexOf is not a function',
        'a[b].target.className.indexOf is not a function',
        'Uncaught ReferenceError: browser is not defined',
        'browser is not defined',
        'SyntaxError: Unexpected end of input',
        'SyntaxError: Unexpected token \'else\'',
        'SyntaxError: Unexpected identifier',
        'SyntaxError',
        'TypeMismatchError: TypeMismatchError',
        'TypeMismatchError',
        'HierarchyRequestError: Failed to execute \'appendChild\' on \'Node\': Only one element on document allowed.',
        'Failed to execute \'appendChild\' on \'Node\': Only one element on document allowed.',
        'HierarchyRequestError',
        'ReferenceError: Can\'t find variable: autoheightTem',
        'Can\'t find variable: autoheightTem',
        'TypeError: n.onClickLogo is not a function',
        'n.onClickLogo is not a function',
        'InvalidStateError: Failed to execute \'transaction\' on \'IDBDatabase\': The database connection is closing.',
        'Failed to execute \'transaction\' on \'IDBDatabase\': The database connection is closing.',
        'InvalidStateError',
        'ReferenceError: $ is not defined',
        '$ is not defined',
        'Error: [timeout]: Could not fetch copyrights from map tile service',
        'Could not fetch copyrights from map tile service',
        'Error: [timeout]: Could not fetch meta-info from map tile service',
        'Could not fetch meta-info from map tile service',
        'TypeError: undefined is not an object (evaluating \'n.getPropertyValue\')',
        'TypeError: null is not an object (evaluating \'n.title\')',
        'SecurityError: Failed to read the \'cssRules\' property from \'CSSStyleSheet\': Cannot access rules',
        'TypeError: Cannot read property \'-1\' of null',
        'Cannot read property \'-1\' of null',
        'HierarchyRequestError: The operation would yield an incorrect node tree.',
        'Error: InvalidStateError',
        'TypeError: Cannot convert undefined or null to object',
        'TypeError: Cannot read property \'$error\' of undefined',
        'ReferenceError: H is not defined',
        'Error: `translate-n` and `translate-plural` attributes must be used together: %{count} rating .',
        'Error: `translate-n` and `translate-plural` attributes must be used together',
        '`translate-n` and `translate-plural` attributes must be used together: %{count} rating .',
        '`translate-n` and `translate-plural` attributes must be used together',
        'TypeError: Cannot read property \'_avast_submit\' of undefined',
        'Cannot read property \'_avast_submit\' of undefined',
        'Error: Für diesen Vorgang sind nicht genügend Speicherressourcen verfügbar.',
        'TypeError: n.infoTextPlain is undefined',
        'n.infoTextPlain is undefined',
        'ReferenceError: ntp is not defined',
        'ntp is not defined',
        'undefined is not an object (evaluating \'this._startPos.add\')',
        'SecurityError: Permission denied to access property "dispatchEvent" on cross-origin object',
        'moz-extension',
        'TypeError: can\'t redefine non-configurable property "userAgent"',
        'can\'t redefine non-configurable property "userAgent"',
        '7500ms timeout exceeded',
        'mismatched image size',
        'Cannot read properties of null (reading \'containerPointToLayerPoint\')',
        'Cannot read properties of null (reading \'scrollTo\')',
        'Cannot read properties of null (reading \'pause\')',
        'Cannot set properties of null (setting \'currentTime\')',
        'null is not an object (evaluating \'elem.parentNode.removeChild\')',
        'null is not an object (evaluating \'this.f.source.parent\')',
        'Unable to preventDefault',
        'Network Error',
        'TypeError: Cannot read properties of null (reading \'style\')\n',
        'null is not an object (evaluating \'element.style\')',
        'crypto.randomUUID is not a function',
        'Failed to fetch',
        'Premium.addon',
        'NS_ERROR_NOT_INITIALIZED',
        'ResizeObserver loop',
        'this._map is null',
        'NavigationDuplicated',
        '_AutofillCallbackHandler',
        'ReportingObserver',
        'Request failed with status code 401',
        'NetworkError',
        'RequestError',
        'Load failed',
        'getAdPos',
        'ViewportObserver',
        'this.placement.getContainer().getBoundingClientRect',
        'this._resizeCallback is not a function',
        'Graphic rendering with WebGL2 has been disabled',
        'Your browser does not support graphic rendering with WebGL2.',
        'null is not an object (evaluating \'e.contentWindow.postMessage\')',
        'Fetch is aborted',
        'The object can not be cloned',
        '$fetch2(chunks:runtime)',
        'Share canceled',
      ],
      allowUrls: [/https?:\/\/((www)\.)?camping\.info/, /https?:\/\/.*\.civ2\.info/],
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: sampleRate,
      replaysSessionSampleRate: sampleRate,
      replaysOnErrorSampleRate: 1.0,
    };

    if (runtimeConfig.public.appRelease) {
      config.release = runtimeConfig.public.appRelease;
    }

    Sentry.init(config);
  },
});
