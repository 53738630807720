import revive_payload_client_4sVQNw7RlN from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/builds/campinginfo/civ2-nuxt-3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/campinginfo/civ2-nuxt-3/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/builds/campinginfo/civ2-nuxt-3/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/builds/campinginfo/civ2-nuxt-3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _10_init_Npus1VY4ES from "/builds/campinginfo/civ2-nuxt-3/src/plugins/10.init.ts";
import _15_translate_w98hpNeqtd from "/builds/campinginfo/civ2-nuxt-3/src/plugins/15.translate.ts";
import _20_auth_client_WKYxhja0gV from "/builds/campinginfo/civ2-nuxt-3/src/plugins/20.auth.client.ts";
import _30_device_client_NhwFv6iyHM from "/builds/campinginfo/civ2-nuxt-3/src/plugins/30.device.client.ts";
import _40_touch_client_z4lxUfHaSG from "/builds/campinginfo/civ2-nuxt-3/src/plugins/40.touch.client.ts";
import _60_modal_Y6r3EGd5n1 from "/builds/campinginfo/civ2-nuxt-3/src/plugins/60.modal.ts";
import _70_observeVisiblity_UiB1Xj6c0J from "/builds/campinginfo/civ2-nuxt-3/src/plugins/70.observeVisiblity.ts";
import _80_loadExternalScripts_client_2rlXgO6Nwc from "/builds/campinginfo/civ2-nuxt-3/src/plugins/80.loadExternalScripts.client.ts";
import _90_snowplow_client_8fnsItTUMd from "/builds/campinginfo/civ2-nuxt-3/src/plugins/90.snowplow.client.ts";
import _95_sentry_client_kURFp6YIka from "/builds/campinginfo/civ2-nuxt-3/src/plugins/95.sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  i18n_yfWm7jX06p,
  _10_init_Npus1VY4ES,
  _15_translate_w98hpNeqtd,
  _20_auth_client_WKYxhja0gV,
  _30_device_client_NhwFv6iyHM,
  _40_touch_client_z4lxUfHaSG,
  _60_modal_Y6r3EGd5n1,
  _70_observeVisiblity_UiB1Xj6c0J,
  _80_loadExternalScripts_client_2rlXgO6Nwc,
  _90_snowplow_client_8fnsItTUMd,
  _95_sentry_client_kURFp6YIka
]