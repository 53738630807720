<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

/********************
 * PROPS & EMITS    *
 ********************/
export interface CiAwesomeIconProps {
  alt?: string | null;
  icon: IconDefinition;
  ratio?: string;
}
const props = withDefaults(defineProps<CiAwesomeIconProps>(), {
  alt: null,
  ratio: '1',
});

/********************
 * REFS & VARS      *
 ********************/
const dimensions = computed(() => {
  const parsedRatio = parseFloat(props.ratio);
  return parsedRatio * 25;
});
</script>

<template>
  <div
    :style="{ width: `${dimensions}px` }"
    class="icon flex justify-center"
  >
    <svg
      :viewBox="`0 0 ${icon.icon[0]} ${icon.icon[1]}`"
      :width="dimensions"
      :height="dimensions"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title v-if="props.alt">{{ props.alt }}</title>
      <path :d="props.icon.icon[4]" />
    </svg>
  </div>
</template>

<style></style>
