import useFetchService from '@/composables/useFetchService';

export default function useContentService() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const runtimeConfig = useRuntimeConfig();
  const fetchService = useFetchService({ base: runtimeConfig.public.apiUrl });

  /********************
   * FUNCTIONS         *
   ********************/
  function getContent({ lang, url }: { lang: string; url: string }) {
    return fetchService.get(`/content/url/`, lang, { params: { url } });
  }

  function getPagesByPrefix({ lang, prefix }: { lang: string; prefix: string }) {
    return fetchService.get(`/content/pages-by-prefix/${prefix}/`, lang);
  }

  return {
    getContent,
    getPagesByPrefix,
  };
}
