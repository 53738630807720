import { createVfm } from 'vue-final-modal';
import { equals } from 'ramda';

export default defineNuxtPlugin({
  name: 'modal',
  parallel: true,
  setup(nuxtApp) {
    const router = useRouter();
    const vfm = createVfm() as any;

    nuxtApp.vueApp.use(vfm);

    router.afterEach((to, from) => {
      if (to.name !== from.name || !equals(to.params, from.params)) {
        vfm.closeAll();
      }
    });
  },
});
