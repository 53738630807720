import { omit, without } from 'ramda';
import { defineStore, acceptHMRUpdate } from 'pinia';

export const useLoadingStore = defineStore('loading', () => {
  /********************
   * REFS & VARS       *
   ********************/
  type State = {
    error: any;
    loading: string[];
    success: string[];
  };

  const state = reactive<State>({
    error: {},
    loading: [],
    success: [],
  });

  const isLoading = computed(() => {
    return (name: string) => (name ? state.loading.includes(name) : false);
  });

  /********************
   * FUNCTIONS         *
   ********************/
  function setStatusLoading(name: string): void {
    // add to loading
    if (!state.loading.includes(name)) {
      state.loading.push(name);
    }

    // remove from other
    state.error = omit([name], state.error);
    state.success = without([name], state.success);
  }

  function setStatusSuccess(name: string): void {
    // add to success
    if (!state.success.includes(name)) {
      state.success.push(name);
    }

    // remove from other
    state.error = omit([name], state.error);
    state.loading = without([name], state.loading);
  }

  function setStatusError({ name, error }: { name: string; error: any }): void {
    // add to error
    state.error[name] = error?.data;

    // remove from other
    state.loading = without([name], state.loading);
    state.success = without([name], state.success);
  }

  function resetLoadingState(): void {
    state.error = {};
    state.loading = [];
    state.success = [];
  }

  return {
    isLoading,
    resetLoadingState,
    setStatusError,
    setStatusLoading,
    setStatusSuccess,
    state,
  };
});

export type LoadingStore = ReturnType<typeof useLoadingStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLoadingStore, import.meta.hot));
}
