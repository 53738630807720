import { newTracker, trackPageView, trackStructEvent, setCustomUrl, setOptOutCookie } from '@snowplow/browser-tracker';
import { PerformanceNavigationTimingPlugin } from '@snowplow/browser-plugin-performance-navigation-timing';
import { LinkClickTrackingPlugin, trackLinkClick } from '@snowplow/browser-plugin-link-click-tracking';
import { AdTrackingPlugin, trackAdClick, trackAdImpression } from '@snowplow/browser-plugin-ad-tracking';
import {
  type AdImpressionEvent,
  type CommonEventProperties,
  type StructuredEvent,
} from '@snowplow/tracker-core';
import { getCookie, setCookie } from '@/utils/cookies';

export default defineNuxtPlugin({
  name: 'snowplow',
  parallel: true,
  setup() {
    let contentTimeout: null | ReturnType<typeof setTimeout> = null;
    let pageViewTimeout: null | ReturnType<typeof setTimeout> = null;
    let pageViewReady: boolean = false;

    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const runtimeConfig = useRuntimeConfig();
    const loadingStore = useLoadingStore(nuxtApp.$pinia);

    const OPT_OUT_COOKIE_NAME: string = '_sp_optout';
    const pageviewRelevantLoadingStates = [
      'getBootstrap',
      'getCampsite',
      'getContent',
      'getHomeData',
      'getSearchResults',
      'getMapSearchResults',
      'getLocationSearchResults',
      'login',
      'logout',
    ];

    newTracker('sp', runtimeConfig.public.snowplowUrl, {
      appId: 'civ2-frontend-v3',
      platform: 'web',
      cookieDomain: runtimeConfig.public.snowplowCookieDomain as string,
      cookieName: '_sp_',
      cookieSameSite: 'Lax',
      respectDoNotTrack: true,
      eventMethod: 'post',
      postPath: runtimeConfig.public.snowplowPostPath as string,
      preservePageViewIdForUrl: 'pathnameAndSearch',
      contexts: {
        browser: true,
        session: true,
        webPage: true,
      },
      plugins: [LinkClickTrackingPlugin(), PerformanceNavigationTimingPlugin(), AdTrackingPlugin()],
    });

    setOptOutCookie(OPT_OUT_COOKIE_NAME);

    function setPageViewPending() {
      pageViewReady = false;
    }

    function hasAdSlots() {
      return document.getElementsByClassName('ci-ad-slot').length > 0;
    }

    async function _trackAdImpression(event: AdImpressionEvent & CommonEventProperties, _trackers?: Array<string>) {
      if (!pageViewReady) {
        setTimeout(() => {
          _trackAdImpression(event, _trackers);
        }, 100);
        return;
      }

      return trackAdImpression(event, _trackers);
    }

    async function _trackStructEvent(event: StructuredEvent & CommonEventProperties, trackers?: Array<string>) {
      if (!pageViewReady) {
        setTimeout(() => {
          _trackStructEvent(event, trackers);
        }, 100);
        return;
      }
      return trackStructEvent(event, trackers);
    }

    function _trackPageView() {
      if (!import.meta.client) {
        return;
      }

      if (loadingStore.state.loading.filter(v => pageviewRelevantLoadingStates.includes(v)).length) {
        if (contentTimeout) {
          clearTimeout(contentTimeout);
        }
        contentTimeout = setTimeout(() => {
          _trackPageView();
        }, 100);
      } else {
        nextTick(async () => {
          pageViewTimeout && clearTimeout(pageViewTimeout);
          contentTimeout && clearTimeout(contentTimeout);

          const headerTags = await nuxtApp.vueApp._context.provides.usehead.resolveTags();
          const pageTitle = headerTags?.find((tag: any) => tag.tag === 'title')?.textContent;
          const url = getCurrentURL(runtimeConfig.public.baseUrl, route);

          pageViewTimeout = setTimeout(() => {
            setCustomUrl(url);
            trackPageView({
              title: pageTitle || document.title,
            });
            pageViewReady = true;
            // @ts-check
            if (!window.__firstPageView) {
              // @ts-check
              window.__firstPageView = true;
            } else {
              // @ts-check
              window.gtag?.('set', 'page_path', decodeURIComponent(route.fullPath));
              // @ts-check
              window.gtag?.('event', 'page_view', { page_has_ads: hasAdSlots() });
              taboolaPageView();
              outbrainPageview();
              facebookPageview();
            }
          }, 50);
        });
      }
    }

    function optOutActive(): boolean {
      return !!getCookie(OPT_OUT_COOKIE_NAME);
    }

    function optOut(): void {
      setCookie(OPT_OUT_COOKIE_NAME, '1', 365);
    }

    function optIn(): void {
      setCookie(OPT_OUT_COOKIE_NAME, '', -1);
    }

    return {
      provide: {
        optIn,
        optOut,
        optOutActive,
        setCustomUrl,
        setPageViewPending,
        trackAdClick,
        trackAdImpression: _trackAdImpression,
        trackLinkClick,
        trackPageView: _trackPageView,
        trackStructEvent: _trackStructEvent,
      },
    };
  },
});
