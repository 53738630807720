import { defineStore, acceptHMRUpdate } from 'pinia';
import { useLoadingStore } from '@/stores/loading';

export const useAppStore = defineStore('app', () => {
  /********************
   * REFS & VARS       *
   ********************/
  const clickContext = ref<string | null>(null);
  const cookies = ref(['required', 'optional']);
  const floorAdActive = ref(false);
  const floorAdClosed = ref(false);
  const isInitialRequest = ref(true);
  const permissions = shallowRef({});
  const routes = shallowRef([]);
  const settings = shallowRef({});
  const sidebarOpen = ref(false);
  const userMenuOpen = ref(false);

  /********************
   * COMPOSITIONS      *
   ********************/
  const bootstrapService = useBootstrapService();
  const seoStore = useSeoStore();
  const spService = useSpService();
  const { setStatusError, setStatusLoading, setStatusSuccess } = useLoadingStore();

  /********************
   * FUNCTIONS         *
   ********************/
  async function getBootstrap({ lang }: { lang: string }) {
    setStatusLoading('getBootstrap');

    try {
      const response = await bootstrapService.getBootstrap(lang);

      const mappedRoutes = response.routes.map((routeEntries) => {
        return routeEntries.map((entry) => {
          return { ...entry, url: `/${entry.lang}${entry.url}` };
        });
      });

      permissions.value = response?.permissions || permissions.value;
      routes.value = response?.routes ? mappedRoutes : routes.value;
      settings.value = response?.settings || settings.value;

      if (response?.seo) {
        seoStore.seo = { ...response.seo, ...(response.num_campsites && { num_campsites: response.num_campsites }) };
        seoStore.setDynamicMetaParam(toRaw(seoStore.seo));
      }

      setStatusSuccess('getBootstrap');
      return response;
    } catch (error) {
      setStatusError({ name: 'getBootstrap', error });
      console.error(error);
      throw error;
    }
  }

  async function ti({ slug, lang, ti, te }: { slug: string; lang: string; ti: string; te: string }) {
    try {
      return await spService.ti(lang, { slug, ti, te });
    } catch (e) {
      return null;
    }
  }

  return {
    clickContext,
    cookies,
    floorAdActive,
    floorAdClosed,
    getBootstrap,
    isInitialRequest,
    permissions,
    routes,
    settings,
    sidebarOpen,
    ti,
    userMenuOpen,
  };
});

export type AppStore = ReturnType<typeof useAppStore>;

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot));
}
