<script setup lang="ts">
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiErrorViewProps {
  error: any;
}
const props = withDefaults(defineProps<CiErrorViewProps>(), {});

/********************
 * COMPOSITIONS      *
 ********************/
const contentStore = useContentStore();
const localePath = useLocalePath();
const { $pgettext, $gettext } = useGettext();

useHead({
  title: getMetaTranslation('defaultTitle'),
  meta: [
    {
      name: 'description',
      content: getMetaTranslation('defaultDescription'),
    },
  ],
});

/********************
 * REFS & VARS       *
 ********************/
const statusCode = computed(() => {
  return props.error?.statusCode;
});

const is404 = computed(() => {
  return statusCode.value === 404;
});

const is410 = computed(() => {
  return statusCode.value === 410;
});

const subline = computed(() => {
  return is404.value || is410.value
    ? $gettext('Sorry, we couldn’t find that page!')
    : $gettext('Sorry, something went wrong. Please try again.');
});

const headline = computed(() => {
  switch (true) {
    case statusCode.value === 404:
      return $pgettext('error page 404 headline', 'Oh no, did you get lost?');

    case statusCode.value >= 500:
      return $pgettext('error page 5xx headline', 'Oh no, a server error occurred!');

    default:
      return contentStore.h1;
  }
});

/********************
 * FUNCTIONS         *
 ********************/
function getMetaTranslation(key) {
  const t = metaData.error[key];
  if (t !== undefined && typeof t === 'object') {
    return $pgettext(t.ctx, t.key);
  }
  return t;
}
</script>

<template>
  <div class="error-view min-h-full pt-12">
    <h1 class="text-center">
      {{ headline }}
    </h1>
    <div class="pb-12 pt-6">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <p>{{ subline }}</p>
            <p v-if="!is404 && !is410" class="text-gray-50 text-xs">
              Error code: {{ statusCode }}
            </p>

            

            <nuxt-link :to="localePath('index')" class="button button--primary mt-2">
              <span>{{ $gettext('Back to camping.info home') }}</span>
              <CiAwesomeIcon :icon="faChevronRight" ratio="0.6" />
            </nuxt-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <img
              v-if="is404"
              class="size-full"
              loading="lazy"
              src="~/assets/img/404-mask-min.png"
              alt="404"
              width="100%"
              height="100%"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
